import * as React from "react"
import Toggle from "./toggle"
import Box from "./box"
import Item from "./item"
import { items } from "../../data/menu"
import LoginBtn from "./loginBtn"
import CountryFlag from "./country-flag"
import styled, { css } from "styled-components"

const RightBox = styled.div`
  display: none;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  @media (max-width: 766px) {
    display: flex;
  }
`

const Ele = () => {
  const [state, setState] = React.useState(false)
  const handleClick = e => {
    setState(e)
  }
  return (
    <>
      <RightBox>
        <LoginBtn />
        <Toggle click={e => handleClick(e)} />
      </RightBox>

      <Box visible={state}>
        {items &&
          items.map((btn, index) => {
            return (
              <Item
                submenuItems={btn.submenu}
                submenuClass={btn.submenuClass}
                key={index}
                to={btn.to}
                label={btn.label}
                cta={btn.cta}
              />
            )
          })}
        <CountryFlag countryCode="UG" />
      </Box>
    </>
  )
}
export default Ele
