import * as React from "react"
import styled, { keyframes } from "styled-components"
import { Link } from "gatsby"
import SubmenuLink from "./submenu-link"

const fadeInLeft = keyframes`
    0%{
        transform: translate3d(-1rem, 0, 0); 
        opacity: 0; 
    }

    100% {
        transform: none; 
        opacity: 1
    }
`

const Submenu = styled.div`
  position: absolute;
  top: 4rem;
  right: 0;
  background-color: #060d1a;
  border-radius: 9px;
  padding: 1.5rem 1.5rem 1rem;
  padding-right: 1rem;
  overflow: hidden;
  z-index: 999999;
  display: none;
  animation: ${fadeInLeft} 0.65s cubic-bezier(0.22, 1, 0.36, 1) both;

  &.two-column {
    flex-wrap: wrap;
    width: 27rem;
    > a {
      width: 50%;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(225deg, rgb(0 226 194 / 0%) 75%, #00e2c2);
    opacity: 10%;
    z-index: -1;
  }

  @media (max-width: 766px) {
    position: static;
    border-radius: 0;

    &.two-column {
      width: 100%;
    }
  }
`

const Box = styled.li`
  height: 100%;
  position: relative;

  &:hover {
    .submenu-wrap {
      display: block;

      &.two-column {
        display: flex;
      }
    }
  }

  .link {
    height: 100%;
    padding: 0 1.125rem;
    position: relative;
    top: 0.125rem;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 0.65rem;
    font-weight: bold;
    letter-spacing: 0.03rem;
    transition: color 0.3s;
    color: #fff;

    @media (prefers-color-scheme: light) {
      color: #000;
    }

    &:hover {
      color: var(--c-mint-100);

      span {
        &:before {
          transform: none;
        }
        color: #000;
      }
    }

    span {
      display: block;
      border: solid 1px var(--c-mint-100);
      padding: 0.5rem 1.5rem;
      border-radius: 5rem;
      position: relative;
      overflow: hidden;
      z-index: 1;
      transition: color 0.3s;

      &:before {
        position: absolute;
        content: "";
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        z-index: -1;
        background: var(--c-mint-100);
        transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
        transform: scale3d(0, 1, 1) skewX(-56deg) translate3d(-20px, 0, 0);
        transform-origin: 0 0;
      }

      @media (prefers-color-scheme: light) {
        border-color: var(--c-green-200);
      }
    }
  }

  &:last-child {
    .link {
      padding-right: 0;
    }
  }

  @media (max-width: 766px) {
    height: auto;
    border-bottom: solid 1px rgba(255, 255, 255, 0.05);

    .submenu-wrap {
      flex-wrap: wrap;
      width: 100%;

      > a {
        width: 50%;
        padding-bottom: 1rem;
      }
    }

    &:hover {
      .submenu-wrap {
        display: flex;
      }
    }
    .link {
      padding: 0;
      position: static;
      padding: 1.25rem 2rem;
      height: auto;
    }
  }
`

const addUnderscores = text => {
  return text.replace(/ /g, "_")
}

const Ele = ({ label, to, cta, submenuItems, submenuClass, id }) => {
  return (
    <Box>
      {!cta && to && to.substr(0, 4) === "http" && (
        <a href={to} rel="noreferrer" className="link">
          {label}
        </a>
      )}

      {!cta && to && to.substr(0, 4) !== "http" && (
        <Link className="link" to={to} id={label}>
          {label}
        </Link>
      )}

      {cta && (
        <a href={to} rel="noreferrer" className="link" id={label}>
          <span>{label}</span>
        </a>
      )}

      {submenuItems && (
        <Submenu className={"submenu-wrap " + submenuClass}>
          {submenuItems &&
            submenuItems.map((item, index) => {
              return (
                <a
                  href={item.to}
                  key={index}
                  id={
                    "submenu_item_" + addUnderscores(item.label.toLowerCase())
                  }
                >
                  <SubmenuLink
                    label={item.label}
                    byline={item.byline}
                    icon={item.icon}
                  ></SubmenuLink>
                </a>
              )
            })}
        </Submenu>
      )}
    </Box>
  )
}
export default Ele
