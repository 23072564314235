import * as React from "react"
import styled from "styled-components"
import Menu from "../menu/container"
import Logo from "./logo"
import PressBanner from "./press-banner"

const Box = styled.header`
  position: absolute;
  left: 0;
  /* top: 3rem; */
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Ele = () => {
  return (
    <>
      {/* <PressBanner /> */}
      <Box>
        <Logo />
        <Menu />
      </Box>
    </>
  )
}
export default Ele
