import * as React from "react"
import styled from "styled-components"

const Box = styled.div`
  background-color: #f6ebc1;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  p {
    font-size: 0.75rem;
    color: #000;
    text-align: center;
    letter-spacing: -0.15px;
    justify-content: center;
    line-height: 1;
    padding: 0.75rem;

    a {
      text-decoration: underline;
      color: #000;
      margin-left: 0.25rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const Ele = () => {
  return (
    <Box>
      <p>
        Currently experiencing system instability. We're working to resolve as
        soon as possible.
        <a href="https://learn.myxeno.com/articles/migration-status---live-update-">
          Follow Live blog
        </a>
      </p>
    </Box>
  )
}
export default Ele
