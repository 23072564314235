import * as React from "react"
import styled from "styled-components"

const Box = styled.div`
  height: 1.85rem;
  border: solid 1px var(--c-mint-100);
  padding: 0 1.125rem;
  display: none;
  align-items: center;
  justify-content: center;
  top: 0.85rem;
  right: 5.35rem;
  border-radius: 10rem;
  background-color: var(--c-blue-100);

  a {
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--c-mint-100);
    font-weight: bold;
  }

  @media (max-width: 766px) {
    display: flex;
  }

  @media (prefers-color-scheme: light) {
    background-color: #f2f2f2;
    a {
      color: var(--c-blue-100);
    }
  }
`

const Ele = () => {
  return (
    <Box>
      <a
        href="https://invest.myxeno.com/login"
        target="_blank"
        rel="noreferrer"
      >
        Login
      </a>
    </Box>
  )
}
export default Ele
